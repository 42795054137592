import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { ExportService } from '@app/services/export.service';
import { PaymentsService } from '@app/services/payments.service';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { capitalize } from 'lodash';
import { combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';
import { FiscalReceiptRegisterService } from '../../shared/invoice/services/fiscal-receipt-register.service';
import { TemporaryInvoiceService } from '../../services/temporary-invoice.service';
import { IInvoiceLayout, InvoiceDetails } from '../../models';
import { effectHooks } from '../../helpers';
import { InvoicesLayoutsService } from '../../services/invoices-layouts.service';

@Injectable()
export class PaymentsStoreEffects {
  constructor(
    private actions$: Actions,
    private exportService: ExportService,
    private dataService: PaymentsService,
    private _translate: TranslateService,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private invoicesLayoutsService: InvoicesLayoutsService,
    private temporaryInvoiceService: TemporaryInvoiceService,
    private fiscalReceiptRegisterService: FiscalReceiptRegisterService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService
          .load(action.payload.reservationId, [
            action.payload.reservationAccommodationId,
          ])
          .pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadSuccessAction({
                  items: data,
                }),
            ),
            catchError((error) => {
              this.notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant(
                  'notifications.delete_failure',
                  {
                    param: this._translate.instant('payments'),
                  },
                ),
                type: 'error',
              });
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  loadDetailsRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDetailsRequestAction>(
        featureActions.ActionTypes.LOAD_DETAILS_REQUEST,
      ),
      switchMap((action: featureActions.LoadDetailsRequestAction) =>
        this.dataService.loadDetails(action.payload.paymentId).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadDetailsSuccessAction({
                data: data[0],
              }),
          ),
          catchError((error) => {
            this.notifications.push({
              title: this._translate.instant('error'),
              content: this._translate.instant('notifications.delete_failure', {
                param: this._translate.instant('payments'),
              }),
              type: 'error',
            });
            return of(new featureActions.LoadDetailsFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  searchRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SearchRequestAction>(
        featureActions.ActionTypes.SEARCH_REQUEST,
      ),
      switchMap((action: featureActions.SearchRequestAction) =>
        this.dataService.search(action.payload).pipe(
          map(
            ({ data }: any) =>
              new featureActions.SearchSuccessAction({
                results: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.SearchFailureAction({ error }));
          }),
        ),
      ),
    ),
  );

  loadAdvancesRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadAdvancesRequestAction>(
        featureActions.ActionTypes.LOAD_ADVANCES_REQUEST,
      ),
      switchMap((action: featureActions.LoadAdvancesRequestAction) => {
        const { propertiesIds, filters } = action.payload;
        return this.dataService.loadAdvances(propertiesIds, filters).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadAdvancesSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadAdvancesFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap(
        ({
          payload: { reservationId, payment, newVersion, hooks },
        }: featureActions.CreateRequestAction) =>
          this.dataService.create(reservationId, payment, newVersion).pipe(
            switchMap((paymentResponse: IResponseSuccess) => {
              const {
                data: [paymentDetails],
              } = paymentResponse;

              // è una ricevuta d'acconto che deve essere stampata,
              // inserisco il flusso di comunicazione con la stampante fiscale
              if (
                paymentDetails?.advance &&
                paymentDetails?.is_temporary &&
                paymentDetails?.type === 'receipt'
              ) {
                const invoice$ = this.getTemporaryInvoice(paymentDetails.id);

                const invoiceLayout$ = this.getInvoiceLayout(
                  paymentDetails.invoice_layout_id,
                );

                return combineLatest([invoice$, invoiceLayout$]).pipe(
                  switchMap(([invoice, layout]) =>
                    this.fiscalReceiptRegisterService
                      .register({
                        invoice,
                        layout,
                        registerService: this.temporaryInvoiceService,
                        registerType: 'receipt',
                        invoiceCashOut: {
                          payment_methods: [],
                          reservation_payments: [],
                        },
                        xmlForRegisterExtraPayload: {
                          payment_methods: [
                            {
                              id: payment.payment_method_id,
                              amount: payment.amount,
                            },
                          ],
                        },
                      })
                      .pipe(map(() => paymentResponse)),
                  ),
                );
              }

              return of(paymentResponse);
            }),
            effectHooks(hooks),
            map(({ data, meta }) => {
              if (meta?.warnings?.length) {
                meta?.warnings.forEach((warning: string) => {
                  this.notifications.done(warning);
                });
              } else {
                this.notifications.push({
                  title: this._translate.instant('done'),
                  content: this._translate.instant(
                    'notifications.create_success',
                    {
                      param: this._translate.instant('payment'),
                    },
                  ),
                  type: 'success',
                });
              }

              const [paymentDetails] = data;

              if (!paymentDetails) {
                return new featureActions.CreateSuccessAction({});
              }

              if (payment.deposit || payment.issue_payment) {
                const dataPrint: any = { ...data[0] };
                const exportLink = `payment/${dataPrint.id}/print?type=single_copy&orientation=landscape&splittedScreen=1`;
                this.exportService.directExport(exportLink);
              }

              if (payment.advance && !payment.fp_send) {
                const dataPrint: any = { ...data[0] };

                this.exportService.printInvoice({
                  invoice_id: dataPrint.id,
                  download: 0,
                  title: capitalize(this._translate.instant('advance')),
                });
              }

              return new featureActions.CreateSuccessAction({});
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.CreateFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap(
        ({
          payload: { reservation_payment_id, reservation_id },
        }: featureActions.DeleteRequestAction) =>
          this.dataService.delete(reservation_id, reservation_payment_id).pipe(
            mergeMap(() => {
              this.notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.delete_success',
                  {
                    param: this._translate.instant('payment'),
                  },
                ),
                type: 'success',
              });

              const effects: Action[] = [
                new featureActions.DeleteSuccessAction({}),
              ];

              return effects;
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  updateRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap(
        ({
          payload: {
            reservationId,
            payment,
            paymentId,
            noReload,
            newVersion,
            emitDepositDocumentFromPayment,
          },
        }: featureActions.UpdateRequestAction) =>
          this.dataService
            .update(reservationId, paymentId, payment, newVersion)
            .pipe(
              mergeMap((response: IResponseSuccess) => {
                this.notifications.push({
                  title: this._translate.instant('done'),
                  content: this._translate.instant(
                    'notifications.update_success',
                    {
                      param: this._translate.instant('payment'),
                    },
                  ),
                  type: 'success',
                });

                if (emitDepositDocumentFromPayment) {
                  const exportLink = `payment/${paymentId}/print?type=single_copy&orientation=landscape&splittedScreen=1`;
                  this.exportService.directExport(exportLink);
                }

                const effects: Action[] = [
                  new featureActions.UpdateSuccessAction({}),
                ];

                return effects;
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(new featureActions.UpdateFailureAction({ error }));
              }),
            ),
      ),
    ),
  );

  sendViaEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SendViaEmailRequest>(
        featureActions.ActionTypes.SEND_VIA_EMAIL_REQUEST,
      ),
      switchMap(({ payload }: featureActions.SendViaEmailRequest) =>
        this.dataService.sendViaEmail(payload).pipe(
          map(() => {
            this.notifications.updateSuccess('payment');

            return new featureActions.SendViaEmailSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.SendViaEmailFailure({ error }));
          }),
        ),
      ),
    ),
  );

  private getTemporaryInvoice(
    temporaryInvoiceId: number,
  ): Observable<InvoiceDetails> {
    return this.temporaryInvoiceService
      .load(temporaryInvoiceId)
      .pipe(
        map(
          ({ data: [invoice] }: IResponseSuccess<InvoiceDetails[]>) => invoice,
        ),
      );
  }

  private getInvoiceLayout(
    invoiceLayoutId: number,
  ): Observable<IInvoiceLayout> {
    return this.invoicesLayoutsService
      .loadDetails(invoiceLayoutId)
      .pipe(
        map(
          ({ data: [invoiceLayout] }: IResponseSuccess<IInvoiceLayout[]>) =>
            invoiceLayout,
        ),
      );
  }
}
