import { PaymentsSearchResult } from '../../../models';

export const paymentsSearchResultsMap = (payment: PaymentsSearchResult) => {
  const { refunds, amount } = payment;

  const total =
    +amount -
    (refunds || []).reduce(
      (tot, { amount: refAmount }) => (tot += +refAmount),
      0,
    );

  const total_to_refund = total < 0 ? 0 : total;

  return {
    ...payment,
    total_to_refund,
  };
};
