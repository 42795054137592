import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { IPaymentData, PaymentRow, PaymentsSearchResult } from '../../models';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getData = (state: State): IPaymentData => state.data;

export const getAdvances = (state: State): IPaymentData => state.advances;

export const selectPaymentsState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('payments');

export const selectPaymentsError: MemoizedSelector<
  object,
  any
> = createSelector(selectPaymentsState, getError);
export const selectAllPaymentsItems: MemoizedSelector<
  object,
  any
> = createSelector(selectPaymentsState, getData);

export const selectAdvances: MemoizedSelector<object, any> = createSelector(
  selectPaymentsState,
  getAdvances,
);

export const selectPaymentsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectPaymentsState, getIsLoading);

export const selectPaymentsSearchResults: MemoizedSelector<
  object,
  PaymentsSearchResult[]
> = createSelector(selectPaymentsState, (state: State) => state.searchResults);

export const selectPaymentDetails: MemoizedSelector<
  object,
  PaymentRow
> = createSelector(selectPaymentsState, (state: State) => state.details);
