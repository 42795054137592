import { Actions, ActionTypes } from './actions';
import { paymentsSearchResultsMap } from './functions/search-results-map';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      return {
        ...state,
        data: action.payload.items,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_DETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.payload.data,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_DETAILS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_ADVANCES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_ADVANCES_SUCCESS: {
      return {
        ...state,
        advances: action.payload.items,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.LOAD_ADVANCES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.DELETE_SUCCESS: {
      const { paymentId, payment_method_id } = action.payload;

      if (!paymentId || !payment_method_id) {
        return { ...state };
      }

      const newData = this.state.data.payments_received.details[
        payment_method_id
      ].filter((payment) => payment.reservation_payment_id !== paymentId);
      return {
        ...state,
        data: newData,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return state;
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.SEARCH_REQUEST: {
      return {
        ...state,
        error: null,
      };
    }

    case ActionTypes.SEARCH_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    case ActionTypes.SEARCH_SUCCESS: {
      const { results } = action.payload;

      return {
        ...state,
        searchResults: (results || []).map(paymentsSearchResultsMap),
      };
    }

    case ActionTypes.SEND_VIA_EMAIL_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }

    case ActionTypes.SEND_VIA_EMAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.SEND_VIA_EMAIL_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}
