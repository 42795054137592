import {
  IPaymentRequest,
  PaymentRow,
  PaymentsSearchResult,
  SendPaymentViaEmail,
} from '@app/models';
import { Action } from '@ngrx/store';

import { IPaymentData } from '../../models';
import { LoadAdvanceInvoicesRequest } from '../../models/requests/load-advance-invoices.request';
import { PaymentsSearchRequest } from '../../models/requests/payments-search.request';
import { Hooks } from '../../helpers';

export enum ActionTypes {
  LOAD_REQUEST = '[Payments] Load Request',
  LOAD_FAILURE = '[Payments] Load Failure',
  LOAD_SUCCESS = '[Payments] Load Success',

  LOAD_DETAILS_REQUEST = '[Payments] Load Details Request',
  LOAD_DETAILS_FAILURE = '[Payments] Load Details Failure',
  LOAD_DETAILS_SUCCESS = '[Payments] Load Details Success',

  LOAD_ADVANCES_REQUEST = '[Payments] Load Advances Request',
  LOAD_ADVANCES_FAILURE = '[Payments] Load Advances Failure',
  LOAD_ADVANCES_SUCCESS = '[Payments] Load Advances Success',

  CREATE_REQUEST = '[Payments] Create Request',
  CREATE_FAILURE = '[Payments] Create Failure',
  CREATE_SUCCESS = '[Payments] Create Success',

  DELETE_REQUEST = '[Payments] Delete Request',
  DELETE_FAILURE = '[Payments] Delete Failure',
  DELETE_SUCCESS = '[Payments] Delete Success',

  UPDATE_REQUEST = '[Payments] Update Request',
  UPDATE_FAILURE = '[Payments] Update Failure',
  UPDATE_SUCCESS = '[Payments] Update Success',

  SEARCH_REQUEST = '[Payments] Search Request',
  SEARCH_FAILURE = '[Payments] Search Failure',
  SEARCH_SUCCESS = '[Payments] Search Success',

  SEND_VIA_EMAIL_REQUEST = '[Payments] Send Via Email Request',
  SEND_VIA_EMAIL_FAILURE = '[Payments] Send Via Email Failure',
  SEND_VIA_EMAIL_SUCCESS = '[Payments] Send Via Email Success',

  RESET_STATE = '[Payments] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(
    public payload: {
      reservationId: number;
      reservationAccommodationId?: number;
    },
  ) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: IPaymentData }) {}
}

export class LoadDetailsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_REQUEST;
  constructor(public payload: { paymentId: number }) {}
}

export class LoadDetailsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadDetailsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_SUCCESS;
  constructor(public payload: { data: PaymentRow }) {}
}

export class LoadAdvancesRequestAction implements Action {
  readonly type = ActionTypes.LOAD_ADVANCES_REQUEST;
  constructor(public payload: LoadAdvanceInvoicesRequest) {}
}

export class LoadAdvancesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ADVANCES_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadAdvancesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ADVANCES_SUCCESS;
  constructor(public payload: { items: IPaymentData }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      reservationId?: number;
      payment: IPaymentRequest;
      newVersion?: boolean;
      hooks?: Hooks;
    },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { data?: IPaymentData }) {}
}

export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(
    public payload: {
      reservation_id?: number;
      reservation_payment_id: number;
    },
  ) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(
    public payload: { paymentId?: number; payment_method_id?: number },
  ) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      reservationId?: number;
      payment: IPaymentRequest;
      paymentId: number;
      noReload?: boolean;
      newVersion?: boolean;
      emitDepositDocumentFromPayment?: boolean;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public payload: { payment?: IPaymentData }) {}
}

export class SearchRequestAction implements Action {
  readonly type = ActionTypes.SEARCH_REQUEST;
  constructor(public payload: PaymentsSearchRequest) {}
}

export class SearchFailureAction implements Action {
  readonly type = ActionTypes.SEARCH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SearchSuccessAction implements Action {
  readonly type = ActionTypes.SEARCH_SUCCESS;
  constructor(public payload: { results: PaymentsSearchResult[] }) {}
}

export class SendViaEmailRequest implements Action {
  readonly type = ActionTypes.SEND_VIA_EMAIL_REQUEST;
  constructor(public payload: SendPaymentViaEmail) {}
}

export class SendViaEmailFailure implements Action {
  readonly type = ActionTypes.SEND_VIA_EMAIL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SendViaEmailSuccess implements Action {
  readonly type = ActionTypes.SEND_VIA_EMAIL_SUCCESS;
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | LoadDetailsRequestAction
  | LoadDetailsFailureAction
  | LoadDetailsSuccessAction
  | LoadAdvancesRequestAction
  | LoadAdvancesFailureAction
  | LoadAdvancesSuccessAction
  | CreateRequestAction
  | CreateFailureAction
  | CreateSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | SearchRequestAction
  | SearchSuccessAction
  | SearchFailureAction
  | SendViaEmailRequest
  | SendViaEmailSuccess
  | SendViaEmailFailure
  | ResetSuccessAction;
